<template>
<div>
    <b-row>

        <b-col md="12">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-list fa-md"></i> <span class="h5"> Registro clientes auditados</span>
                </CCardHeader>

                <CCardBody>
                    <b-row>
                        <b-col md="12">
                            <validation-observer ref="observer" v-slot="{ handleSubmit }">
                                <b-form @submit.stop.prevent="handleSubmit(registrarAuditoria)">
                                    <b-row>
                                        <b-col md="6">
                                            <validation-provider name="fecha" :rules="{ required: true }" v-slot="validationContext">
                                                <b-form-group label="Fecha Inicio:" class="mb-2">
                                                    <b-form-input :state="getValidationState(validationContext)" type="date" v-model.lazy="datosAuditoria.fechaInicio"></b-form-input>
                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>
                                        <b-col md="6">
                                            <validation-provider name="fechaFin" :rules="{ required: true }" v-slot="validationContext">
                                                <b-form-group label="Fecha Fin:" class="mb-2">
                                                    <b-form-input :state="getValidationState(validationContext)" type="date" v-model.lazy="datosAuditoria.fechaFin"></b-form-input>
                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>
                                        <b-col md="6">
                                            <validation-provider name="normas" rules="required" v-slot="{errors}">
                                                <b-form-group label="Normas:" class="mb-2">
                                                    <v-select multiple :reduce="listaNormas => listaNormas.idNorma" label="descripcion" placeholder="Seleccione una opción" :class="{'style-valid-select': datosAuditoria.normas.length > 0  , 'style-invalid-select is-invalid': !!errors.length}" v-model.lazy="datosAuditoria.normas" :options="listaNormas">
                                                        <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                                    </v-select>
                                                    <div class="invalid-feedback">{{ errors[0]}}</div>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>

                                        <b-col md="6">
                                            <validation-provider name="tipo de auditoría" rules="required" v-slot="{errors}">
                                                <b-form-group label="Tipo de auditoría:" class="mb-2">
                                                    <v-select :reduce="listaTipoAuditorias => listaTipoAuditorias.idTipoAuditoria" label="descripcion" placeholder="Seleccione una opción" :class="{'style-valid-select': datosAuditoria.tipoAuditoria  , 'style-invalid-select is-invalid': !!errors.length}" v-model.lazy="datosAuditoria.tipoAuditoria" :options="listaTipoAuditorias">
                                                        <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                                    </v-select>
                                                    <div class="invalid-feedback">{{ errors[0]}}</div>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>

                                        <b-col md="6" class=" my-3">
                                            <span class="text-muted h5"><u>Asignar Personal Auditoria</u></span>
                                        </b-col>

                                        <!-- <b-col md="4">
                                            <validation-provider name="auditor" rules="required" v-slot="{errors}">
                                                <b-form-group label="Auditor Lider:" class="mb-2">
                                                    <v-select multiple :reduce="listaPersonal => listaPersonal.idPersona" label="label" placeholder="Seleccione una opción" :class="{'style-valid-select': datosAuditoria.auditorLider.length > 0  , 'style-invalid-select is-invalid': !!errors.length}" v-model.lazy="datosAuditoria.auditorLider" :options="listaPersonal">
                                                        <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                                    </v-select>
                                                    <div class="invalid-feedback">{{ errors[0]}}</div>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>
                                        <b-col md="4">
                                            <validation-provider name="auditor" rules="required" v-slot="{errors}">
                                                <b-form-group label="Co Auditor 1:" class="mb-2">
                                                    <v-select multiple :reduce="listaPersonal => listaPersonal.idPersona" label="label" placeholder="Seleccione una opción" :class="{'style-valid-select': datosAuditoria.coAuditor.length > 0  , 'style-invalid-select is-invalid': !!errors.length}" v-model.lazy="datosAuditoria.coAuditor" :options="listaPersonal">
                                                        <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                                    </v-select>
                                                    <div class="invalid-feedback">{{ errors[0]}}</div>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col> -->

                                        <!-- <b-col md="4">
                                            <validation-provider name="auditor" rules="required" v-slot="{errors}">
                                                <b-form-group label="Co Auditor 2:" class="mb-2">
                                                    <v-select multiple :reduce="listaPersonal => listaPersonal.idPersona" label="label" placeholder="Seleccione una opción" :class="{'style-valid-select': datosAuditoria.coAuditor2.length > 0  , 'style-invalid-select is-invalid': !!errors.length}" v-model.lazy="datosAuditoria.coAuditor2" :options="listaPersonal">
                                                        <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                                    </v-select>
                                                    <div class="invalid-feedback">{{ errors[0]}}</div>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col> -->

                                        <b-col md="6" class="mt-2">
                                            <!-- <span class="h6 text-muted">Lista Auditores</span> -->
                                            <CButton size="sm" class="float-right" color="dark" @click="agregarAuditor">
                                                <i class="fas fa-plus fa-sm"></i> Agregar
                                            </CButton>
                                        </b-col>
                                        <b-col md="12" class="mt-1">
                                            <b-table :items="datosAuditoria.personalAuditoria" :fields="headerAuditor" class="table-custom" fixed responsive show-empty mediun outlined hover empty-text="Aún no hay datos en esta sección.">
                                                <template v-slot:cell(idPersona)="row">
                                                    <b-form-select :options="listaPersonal" value-field="idPersona" text-field="label" v-model="row.item.idPersona" size="md">
                                                        <template v-slot:first>
                                                            <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                                        </template>
                                                    </b-form-select>
                                                </template>
                                                <template #cell(acciones)="param">
                                                    <b-button @click="quitarActividad(param)" v-if="datosAuditoria.personalAuditoria.length >= 2" class="mr-1" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                                        <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                                    </b-button>
                                                </template>
                                            </b-table>
                                        </b-col>

                                        <!-- <b-col md="6">
                                            <validation-provider name="experto1" rules="required" v-slot="{errors}">
                                                <b-form-group label="Experto Técnico 1:" class="mb-2">
                                                    <v-select multiple :reduce="listaAuditor => listaAuditor.idAuditor" label="descripcion" placeholder="Seleccione una opción" :class="{'style-valid-select': datosAuditoria.expertoTecnico.length >0  , 'style-invalid-select is-invalid': !!errors.length}" v-model.lazy="datosAuditoria.expertoTecnico" :options="listaAuditor">
                                                        <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                                    </v-select>
                                                    <div class="invalid-feedback">{{ errors[0]}}</div>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>
                                        <b-col md="6">
                                            <validation-provider name="experto1" rules="required" v-slot="{errors}">
                                                <b-form-group label="Experto Técnico 1:" class="mb-2">
                                                    <v-select multiple :reduce="listaAuditor => listaAuditor.idAuditor" label="descripcion" placeholder="Seleccione una opción" :class="{'style-valid-select': datosAuditoria.expertoTecnico2.length >0  , 'style-invalid-select is-invalid': !!errors.length}" v-model.lazy="datosAuditoria.expertoTecnico2" :options="listaAuditor">
                                                        <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                                    </v-select>
                                                    <div class="invalid-feedback">{{ errors[0]}}</div>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>
                                        <b-col md="12" class="mt-2">
                                            <span class="h6 text-muted">Lista Experto</span>
                                            <CButton size="sm" class="float-right" color="dark" @click="agregarActividad2()">
                                                <i class="fas fa-plus fa-sm"></i> Agregar Experto
                                            </CButton>
                                        </b-col>
                                        <b-col md="12" class="mt-1">

                                            <b-table :items="datosExperto.listaExperto" :fields="cabeceraActividades2" class="table-custom" fixed responsive show-empty mediun outlined hover empty-text="Aún no hay datos en esta sección.">

                                                <template v-slot:cell(experto)="row">
                                                    <v-select :reduce="listaPersonal =>listaPersonal.idPuestoTrabajo" label="nombre" placeholder="Seleccione una opción" v-model="row.item.idPuestoTrabajo" :options="listaPersonal">
                                                        <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Personal cliente'}"> Registra aquí</router-link></span>
                                                    </v-select>

                                                </template>

                                                <template #cell(acciones)="param">
                                                    <b-button @click="quitarActividad2(param)" v-if="datosExperto.listaExperto.length >= 2" class="mr-1" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                                        <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                                    </b-button>
                                                </template>
                                            </b-table>
                                        </b-col> -->

                                        <!-- <b-col md="12">
                                            <validation-provider name="calendario" :rules="{ required: true }" v-slot="validationContext">
                                                <b-form-group label="Calendario :" class="mb-2">
                                                    <b-form-input :state="getValidationState(validationContext)" type="date" v-model.lazy="datosAuditoria.calendario"></b-form-input>
                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col> -->
                                        <b-col md="12" class=" my-2">
                                            <span class="text-muted h5"><u> Lista de Verificación de Documentos</u></span>
                                        </b-col>
                                        <b-col md="6">
                                            <validation-provider name="organizacional" rules="required" v-slot="{errors}">
                                                <b-form-group label="Carga Modulo Organizacional:" class="mb-2">
                                                    <v-select multiple :reduce="listaCheckListInicialOrganizacional => listaCheckListInicialOrganizacional.idCheckListInicial" label="descripcion" placeholder="Seleccione una opción" :class="{'style-valid-select': datosAuditoria.cargaModuloOrganizacional.length > 0  , 'style-invalid-select is-invalid': !!errors.length}" v-model.lazy="datosAuditoria.cargaModuloOrganizacional" :options="listaCheckListInicialOrganizacional">
                                                        <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                                    </v-select>
                                                    <div class="invalid-feedback">{{ errors[0]}}</div>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>
                                        <b-col md="6">
                                            <validation-provider name="gestion" rules="required" v-slot="{errors}">
                                                <b-form-group label="Carga Modulo Gestión:" class="mb-2">
                                                    <v-select multiple :reduce="listaCheckListInicialGestion => listaCheckListInicialGestion.idCheckListInicial" label="descripcion" placeholder="Seleccione una opción" :class="{'style-valid-select': datosAuditoria.cargaModuloGestion.length >0  , 'style-invalid-select is-invalid': !!errors.length}" v-model.lazy="datosAuditoria.cargaModuloGestion" :options="listaCheckListInicialGestion">
                                                        <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                                    </v-select>
                                                    <div class="invalid-feedback">{{ errors[0]}}</div>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>
                                        <b-col md="6">
                                            <validation-provider name="esquema principal" rules="required" v-slot="{errors}">
                                                <b-form-group label="Carga esquema principal:" class="mb-2">
                                                    <v-select :reduce="listaCheckListInciales => listaCheckListInciales.idPackCheckListInicial" label="codigo" placeholder="Seleccione una opción" :class="{'style-valid-select': datosAuditoria.referenciasCheckListIniciales  , 'style-invalid-select is-invalid': !!errors.length}" v-model.lazy="datosAuditoria.referenciasCheckListIniciales" :options="listaCheckListInciales">
                                                        <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                                    </v-select>
                                                    <div class="invalid-feedback">{{ errors[0]}}</div>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>
                                        <b-col md="12" class=" my-2">
                                            <span class="text-muted h5"><u>Lista de auditoria de certificación </u></span>
                                        </b-col>
                                        <!-- <b-col md="6">
                                            <validation-provider name="plan audotoria stage1" rules="required" v-slot="{errors}">
                                                <b-form-group label="Crear Plan de Auditoria Stage1:" class="mb-2">
                                                    <v-select multiple :reduce="listaPlanStage1 => listaPlanStage1.idPlanStage1" label="descripcion" placeholder="Seleccione una opción" :class="{'style-valid-select': datosAuditoria.planAuditoria.length > 0  , 'style-invalid-select is-invalid': !!errors.length}" v-model.lazy="datosAuditoria.planAuditoria" :options="listaPlanStage1">
                                                        <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                                    </v-select>
                                                    <div class="invalid-feedback">{{ errors[0]}}</div>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>
                                        <b-col md="6">
                                            <validation-provider name="check list1" rules="required" v-slot="{errors}">
                                                <b-form-group label="Check List1:" class="mb-2">
                                                    <v-select multiple :reduce="listaCheckList1 => listaCheckList1.idCheckList1" label="descripcion" placeholder="Seleccione una opción" :class="{'style-valid-select': datosAuditoria.checkList. length > 0  , 'style-invalid-select is-invalid': !!errors.length}" v-model.lazy="datosAuditoria.checkList" :options="listaCheckList1">
                                                        <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                                    </v-select>
                                                    <div class="invalid-feedback">{{ errors[0]}}</div>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>
                                        <b-col md="12" class=" my-2">
                                            <span class="text-muted h5"><u>Auditoria de Certificación Stage2</u></span>
                                        </b-col>
                                        <b-col md="6">
                                            <validation-provider name="plan auditoria stage2" rules="required" v-slot="{errors}">
                                                <b-form-group label="Crear Plan de Auditoria Stage2:" class="mb-2">
                                                    <v-select multiple :reduce="listaPlanStage2 => listaPlanStage2.idPlanStage2" label="descripcion" placeholder="Seleccione una opción" :class="{'style-valid-select': datosAuditoria.planAuditoria2.length > 0  , 'style-invalid-select is-invalid': !!errors.length}" v-model.lazy="datosAuditoria.planAuditoria2" :options="listaPlanStage2">
                                                        <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                                    </v-select>
                                                    <div class="invalid-feedback">{{ errors[0]}}</div>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>
                                        <b-col md="6">
                                            <validation-provider name="check list2" rules="required" v-slot="{errors}">
                                                <b-form-group label="Check List2:" class="mb-2">
                                                    <v-select multiple :reduce="listaCheckList2 => listaCheckList2.idCheckList2" label="descripcion" placeholder="Seleccione una opción" :class="{'style-valid-select': datosAuditoria.checkList2.length > 0  , 'style-invalid-select is-invalid': !!errors.length}" v-model.lazy="datosAuditoria.checkList2" :options="listaCheckList2">
                                                        <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                                    </v-select>
                                                    <div class="invalid-feedback">{{ errors[0]}}</div>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col> -->
                                        <b-col md="12">
                                            <b-table-simple bordered show-empty small responsive outlined>
                                                <b-tbody>
                                                    <b-tr>
                                                        <b-td width="5%" class="text-center font-weight-bold">
                                                            N°
                                                        </b-td>
                                                        <b-td width="15%" class="text-center font-weight-bold">
                                                            Categoría
                                                        </b-td>
                                                        <b-td width="20%" class="text-center font-weight-bold">
                                                            Documento
                                                        </b-td>
                                                        <b-td width="40%" class="text-center font-weight-bold">
                                                            Esquema
                                                        </b-td>
                                                    </b-tr>
                                                    <b-tr>
                                                        <b-td class="text-center">
                                                            {{datosAuditoria.referenciasDocumentosPack[0].idPack}}
                                                        </b-td>
                                                        <b-td class="text-center">
                                                            {{datosAuditoria.referenciasDocumentosPack[0].descripcion}}
                                                        </b-td>
                                                        <b-td class="text-center">
                                                            {{datosAuditoria.referenciasDocumentosPack[0].categoria}}
                                                        </b-td>
                                                        <b-td class="text-center">
                                                            <b-form-select :options="listaPacks.listaPlanAuditoriaS1" value-field="data" text-field="data.label" v-model="datosAuditoria.referenciasDocumentosPack[0].data" size="md">
                                                                <template v-slot:first>
                                                                    <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                                                </template>
                                                            </b-form-select>
                                                        </b-td>
                                                    </b-tr>
                                                    <b-tr>
                                                        <b-td class="text-center">
                                                            {{datosAuditoria.referenciasDocumentosPack[1].idPack}}
                                                        </b-td>
                                                        <b-td class="text-center">
                                                            {{datosAuditoria.referenciasDocumentosPack[1].descripcion}}
                                                        </b-td>
                                                        <b-td class="text-center">
                                                            {{datosAuditoria.referenciasDocumentosPack[1].categoria}}
                                                        </b-td>
                                                        <b-td class="text-center">
                                                            <b-form-select :options="listaPacks.listaAsignacionEquipoS1" value-field="data" text-field="data.label" v-model="datosAuditoria.referenciasDocumentosPack[1].data" size="md">
                                                                <template v-slot:first>
                                                                    <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                                                </template>
                                                            </b-form-select>
                                                        </b-td>
                                                    </b-tr>
                                                    <b-tr>
                                                        <b-td class="text-center">
                                                            {{datosAuditoria.referenciasDocumentosPack[2].idPack}}
                                                        </b-td>
                                                        <b-td class="text-center">
                                                            {{datosAuditoria.referenciasDocumentosPack[2].descripcion}}
                                                        </b-td>
                                                        <b-td class="text-center">
                                                            {{datosAuditoria.referenciasDocumentosPack[2].categoria}}
                                                        </b-td>
                                                        <b-td class="text-center">
                                                            <b-form-select :options="listaPacks.listaEstudioConflictosS1" value-field="data" text-field="data.label" v-model="datosAuditoria.referenciasDocumentosPack[2].data" size="md">
                                                                <template v-slot:first>
                                                                    <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                                                </template>
                                                            </b-form-select>
                                                        </b-td>
                                                    </b-tr>
                                                    <b-tr>
                                                        <b-td class="text-center">
                                                            {{datosAuditoria.referenciasDocumentosPack[3].idPack}}
                                                        </b-td>
                                                        <b-td class="text-center">
                                                            {{datosAuditoria.referenciasDocumentosPack[3].descripcion}}
                                                        </b-td>
                                                        <b-td class="text-center">
                                                            {{datosAuditoria.referenciasDocumentosPack[3].categoria}}
                                                        </b-td>
                                                        <b-td class="text-center">
                                                            <b-form-select :options="listaPacks.listaReportS1" value-field="data" text-field="data.label" v-model="datosAuditoria.referenciasDocumentosPack[3].data" size="md">
                                                                <template v-slot:first>
                                                                    <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                                                </template>
                                                            </b-form-select>
                                                        </b-td>
                                                    </b-tr>
                                                    <b-tr>
                                                        <b-td class="text-center">
                                                            {{datosAuditoria.referenciasDocumentosPack[4].idPack}}
                                                        </b-td>
                                                        <b-td class="text-center">
                                                            {{datosAuditoria.referenciasDocumentosPack[4].descripcion}}
                                                        </b-td>
                                                        <b-td class="text-center">
                                                            {{datosAuditoria.referenciasDocumentosPack[4].categoria}}
                                                        </b-td>
                                                        <b-td class="text-center">
                                                            <b-form-select :options="listaPacks.listaPlanAuditoriaS2" value-field="data" text-field="data.label" v-model="datosAuditoria.referenciasDocumentosPack[4].data" size="md">
                                                                <template v-slot:first>
                                                                    <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                                                </template>
                                                            </b-form-select>
                                                        </b-td>
                                                    </b-tr>
                                                    <b-tr>
                                                        <b-td class="text-center">
                                                            {{datosAuditoria.referenciasDocumentosPack[5].idPack}}
                                                        </b-td>
                                                        <b-td class="text-center">
                                                            {{datosAuditoria.referenciasDocumentosPack[5].descripcion}}
                                                        </b-td>
                                                        <b-td class="text-center">
                                                            {{datosAuditoria.referenciasDocumentosPack[5].categoria}}
                                                        </b-td>
                                                        <b-td class="text-center">
                                                            <b-form-select :options="listaPacks.listaAsignacionEquipoS2" value-field="data" text-field="data.label" v-model="datosAuditoria.referenciasDocumentosPack[5].data" size="md">
                                                                <template v-slot:first>
                                                                    <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                                                </template>
                                                            </b-form-select>
                                                        </b-td>
                                                    </b-tr>
                                                    <b-tr>
                                                        <b-td class="text-center">
                                                            {{datosAuditoria.referenciasDocumentosPack[6].idPack}}
                                                        </b-td>
                                                        <b-td class="text-center">
                                                            {{datosAuditoria.referenciasDocumentosPack[6].descripcion}}
                                                        </b-td>
                                                        <b-td class="text-center">
                                                            {{datosAuditoria.referenciasDocumentosPack[6].categoria}}
                                                        </b-td>
                                                        <b-td class="text-center">
                                                            <b-form-select :options="listaPacks.listaEstudioConflictosS2" value-field="data" text-field="data.label" v-model="datosAuditoria.referenciasDocumentosPack[6].data" size="md">
                                                                <template v-slot:first>
                                                                    <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                                                </template>
                                                            </b-form-select>
                                                        </b-td>
                                                    </b-tr>
                                                    <b-tr>
                                                        <b-td class="text-center">
                                                            {{datosAuditoria.referenciasDocumentosPack[7].idPack}}
                                                        </b-td>
                                                        <b-td class="text-center">
                                                            {{datosAuditoria.referenciasDocumentosPack[7].descripcion}}
                                                        </b-td>
                                                        <b-td class="text-center">
                                                            {{datosAuditoria.referenciasDocumentosPack[7].categoria}}
                                                        </b-td>
                                                        <b-td class="text-center">
                                                            <b-form-select :options="listaPacks.listaCheckListS2" value-field="data" text-field="data.label" v-model="datosAuditoria.referenciasDocumentosPack[7].data" size="md">
                                                                <template v-slot:first>
                                                                    <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                                                </template>
                                                            </b-form-select>
                                                        </b-td>
                                                    </b-tr>
                                                    <b-tr>
                                                        <b-td class="text-center">
                                                            {{datosAuditoria.referenciasDocumentosPack[8].idPack}}
                                                        </b-td>
                                                        <b-td class="text-center">
                                                            {{datosAuditoria.referenciasDocumentosPack[8].descripcion}}
                                                        </b-td>
                                                        <b-td class="text-center">
                                                            {{datosAuditoria.referenciasDocumentosPack[8].categoria}}
                                                        </b-td>
                                                        <b-td class="text-center">
                                                            <b-form-select :options="listaPacks.listaReportS2" value-field="data" text-field="data.label" v-model="datosAuditoria.referenciasDocumentosPack[8].data" size="md">
                                                                <template v-slot:first>
                                                                    <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                                                </template>
                                                            </b-form-select>
                                                        </b-td>
                                                    </b-tr>
                                                    <b-tr>
                                                        <b-td class="text-center">
                                                            {{datosAuditoria.referenciasDocumentosPack[9].idPack}}
                                                        </b-td>
                                                        <b-td class="text-center">
                                                            {{datosAuditoria.referenciasDocumentosPack[9].descripcion}}
                                                        </b-td>
                                                        <b-td class="text-center">
                                                            {{datosAuditoria.referenciasDocumentosPack[9].categoria}}
                                                        </b-td>
                                                        <b-td class="text-center">
                                                            <b-form-select :options="listaPacks.listaReportExpertoS2" value-field="data" text-field="data.label" v-model="datosAuditoria.referenciasDocumentosPack[9].data" size="md">
                                                                <template v-slot:first>
                                                                    <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                                                </template>
                                                            </b-form-select>
                                                        </b-td>
                                                    </b-tr>
                                                    <b-tr>
                                                        <b-td class="text-center">
                                                            {{datosAuditoria.referenciasDocumentosPack[10].idPack}}
                                                        </b-td>
                                                        <b-td class="text-center">
                                                            {{datosAuditoria.referenciasDocumentosPack[10].descripcion}}
                                                        </b-td>
                                                        <b-td class="text-center">
                                                            {{datosAuditoria.referenciasDocumentosPack[10].categoria}}
                                                        </b-td>
                                                        <b-td class="text-center">
                                                            <b-form-select :options="listaPacks.listaNoClientFeedbackS2" value-field="data" text-field="data.label" v-model="datosAuditoria.referenciasDocumentosPack[10].data" size="md">
                                                                <template v-slot:first>
                                                                    <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                                                </template>
                                                            </b-form-select>
                                                        </b-td>
                                                    </b-tr>
                                                    <b-tr>
                                                        <b-td class="text-center">
                                                            {{datosAuditoria.referenciasDocumentosPack[11].idPack}}
                                                        </b-td>
                                                        <b-td class="text-center">
                                                            {{datosAuditoria.referenciasDocumentosPack[11].descripcion}}
                                                        </b-td>
                                                        <b-td class="text-center">
                                                            {{datosAuditoria.referenciasDocumentosPack[11].categoria}}
                                                        </b-td>
                                                        <b-td class="text-center">
                                                            <b-form-select :options="listaPacks.listaNonConformityS2" value-field="data" text-field="data.label" v-model="datosAuditoria.referenciasDocumentosPack[11].data" size="md">
                                                                <template v-slot:first>
                                                                    <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                                                </template>
                                                            </b-form-select>
                                                        </b-td>
                                                    </b-tr>
                                                    <b-tr>
                                                        <b-td class="text-center">
                                                            {{datosAuditoria.referenciasDocumentosPack[12].idPack}}
                                                        </b-td>
                                                        <b-td class="text-center">
                                                            {{datosAuditoria.referenciasDocumentosPack[12].descripcion}}
                                                        </b-td>
                                                        <b-td class="text-center">
                                                            {{datosAuditoria.referenciasDocumentosPack[12].categoria}}
                                                        </b-td>
                                                        <b-td class="text-center">
                                                            <b-form-select :options="listaPacks.listaAuditAprovalS2" value-field="data" text-field="data.label" v-model="datosAuditoria.referenciasDocumentosPack[12].data" size="md">
                                                                <template v-slot:first>
                                                                    <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                                                </template>
                                                            </b-form-select>
                                                        </b-td>
                                                    </b-tr>
                                                </b-tbody>
                                            </b-table-simple>

                                        </b-col>
                                        <b-col lg="12" class="text-right my-2">
                                            <b-button variant="success" type="submit">
                                                <i class="fas fa-save"></i> Enviar
                                            </b-button>
                                        </b-col>
                                    </b-row>
                                </b-form>
                            </validation-observer>
                        </b-col>
                    </b-row>

                </CCardBody>

                <CCardFooter>
                    <b-pagination size="sm" class="my-0 float-right" v-model="paginaActual" :total-rows="filasTotales" :per-page="porPagina" first-number last-number></b-pagination>
                </CCardFooter>
            </CCard>
        </b-col>

    </b-row>

</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";

export default {

    data() {
        return {
            listaClientes: [],
            filterBusqueda: {
                idSubCliente: null,
            },

            headerAuditor: [{
                    key: 'idPersona',
                    label: 'Nuevo Auditor',
                },
                {
                    key: 'acciones',
                    label: '',
                    class: 'text-center'

                },

            ],

            cabeceraActividades2: [{
                key: 'experto',
                label: 'Nuevo Experto Técnico',
                class: 'text-center'

            }, {
                key: 'acciones',
                label: '',
                class: 'text-center'

            }, ],

            filasTotales: 0,
            paginaActual: 1,
            porPagina: 10,
            paginaOpciones: [10, 25, 50, 100, {
                value: 9999999,
                text: "Mostrar todos"
            }],
            filter: null,
            filterOn: [],

            // datosAuditor: {
            //     listaAuditor: [],
            // },

            // datosExperto: {
            //     listaExperto: [{
            //         experto: '',
            //         acciones: '',
            //     }]
            // },

            datosAuditoria: {
                idAuditoria: '',
                idCotizacionCertificacion: '',
                // idPersona: '',
                fechaInicio: moment().format('YYYY-MM-DD'),
                fechaFin: moment().format('YYYY-MM-DD'),
                normas: [],
                tipoAuditoria: null,
                personalAuditoria: [],
                // auditorLider: '',
                // coAuditor: '',
                // coAuditor2: '',
                // auditorResponsable: '',
                // expertoResponsable: '',
                // expertoTecnico: '',
                // expertoTecnico2: '',
                // calendario: moment().format('YYYY-MM-DD'),
                cargaModuloOrganizacional: [],
                cargaModuloGestion: [],
                referenciasCheckListIniciales: null,
                referenciasDocumentosPack: [{
                        idPack: 1,
                        descripcion: 'Plan de auditoria',
                        categoria: 'Documentos Stage 1',
                        data: null,
                        formatos: [],
                    }, {
                        idPack: 2,
                        descripcion: 'Asignación de equipo auditor',
                        categoria: 'Documentos Stage 1',
                        data: null,
                        formatos: [],
                    }, {
                        idPack: 3,
                        descripcion: 'Estudio conflictos de intereses solicitantes',
                        categoria: 'Documentos Stage 1',
                        data: null,
                        formatos: [],
                    },
                    {
                        idPack: 4,
                        descripcion: 'Report Stage 1',
                        categoria: 'Ingreso de documentos Stage 1',
                        data: null,
                        formatos: [],
                    }, {
                        idPack: 5,
                        descripcion: 'Plan de auditoria',
                        categoria: 'Documentos Stage 2',
                        data: null,
                        formatos: [],
                    }, {
                        idPack: 6,
                        descripcion: 'Asignación de equipo auditor',
                        categoria: 'Documentos Stage 2',
                        data: null,
                        formatos: [],
                    }, {
                        idPack: 7,
                        descripcion: 'Estudio conflictos de intereses solicitantes',
                        categoria: 'Documentos Stage 2',
                        data: null,
                        formatos: [],
                    }, {
                        idPack: 8,
                        descripcion: 'Check list',
                        categoria: 'Ingreso de documentos Stage 2',
                        data: null,
                        formatos: [],
                    }, {
                        idPack: 9,
                        descripcion: 'Report Stage 2',
                        categoria: 'Ingreso de documentos Stage 2',
                        data: null,
                        formatos: [],
                    }, {
                        idPack: 10,
                        descripcion: 'Reporte Experto Técnico',
                        categoria: 'Ingreso de documentos Stage 2',
                        data: null,
                        formatos: [],
                    }, {
                        idPack: 11,
                        descripcion: 'No Client Feedback',
                        categoria: 'Ingreso de documentos Stage 2',
                        data: null,
                        formatos: [],
                    }, {
                        idPack: 12,
                        descripcion: 'No Conformity Report',
                        categoria: 'Ingreso de documentos Stage 2',
                        data: null,
                        formatos: [],
                    }, {
                        idPack: 13,
                        descripcion: 'Audit Team Approval',
                        categoria: 'Ingreso de documentos Stage 2',
                        data: null,
                        formatos: [],
                    },
                ],
                seguimiento: [{
                    contratosYdocumentos: 0,
                    checkListInicial: 0,
                    packStage1: 0,
                    packStage2: 0
                }],
                // planAuditoria: '',
                // checkList: '',
                // planAuditoria2: '',
                // checkList2: '',
                estado: '',
            },
            // datosNuevoPersonal: {
            //     idCliente: '',
            //     idAuditoria: '',
            //     idSubCliente: 0,
            //     nombre: '',
            //     auditorResponsable: '',
            //     listarPersonal: [{
            //         auditor: '',
            //     }]
            // },
            //DATOS AUDITOR

            // modalAgregarPersonal: false,
            disabled: false,

            //Disabled
            // disabledNorma1: false,
            // disabledNorma2: false,
            // disabledNorma3: false,
            // disabledNorma4: false,
            // disabledNorma5: false,
            // disabledNorma6: false,
            //Listas de Prueba para auditor en duro
            // listaNuevoExperto: [{
            //     idAu: 1,
            //     descripcion: 'Experto técnico',
            // }, ],
            // listaNuevoAuditor: [{
            //     idNuevoAuditor: 1,
            //     descripcion: 'Auditor'
            // }, {
            //     idNuevoAuditor: 2,
            //     descripcion: 'Auditor líder'

            // }],
            listaPersonal: [],
            //Listas Normales
            listaNormas: [{
                    idNorma: 1,
                    descripcion: 'ISO 9001: 2015'
                },
                {
                    idNorma: 2,
                    descripcion: 'ISO 14001: 2015'
                },
                {
                    idNorma: 3,
                    descripcion: 'ISO 45001: 2018'
                },
                {
                    idNorma: 4,
                    descripcion: 'ISO 37001: 2016'
                },
                {
                    idNorma: 5,
                    descripcion: 'ISO 27001: 2013'
                },
                {
                    idNorma: 6,
                    descripcion: 'ISO 20000: 2011'
                },
            ],
            listaTipoAuditorias: [{
                idTipoAuditoria: 1,
                descripcion: 'MS individual',
            }, {
                idTipoAuditoria: 2,
                descripcion: 'MS única',
            }, {
                idTipoAuditoria: 3,
                descripcion: 'MS conjunta',
            }, {
                idTipoAuditoria: 4,
                descripcion: 'Auditoría combinada',
            }, {
                idTipoAuditoria: 5,
                descripcion: 'Integrado'
            }],
            /*    listaAuditor: [{
                   idAuditor: 1,
                   descripcion: 'Juan',
               }, {
                   idAuditor: 2,
                   descripcion: 'Carlos',
               }, {
                   idAuditor: 3,
                   descripcion: 'Jose',
               }, {
                   idAuditor: 4,
                   descripcion: 'Jeancarlo',
               }, {
                   idAuditor: 5,
                   descripcion: 'Miguel'
               }], */
            listaCheckListInicialOrganizacional: [{
                    idCheckListInicial: 1,
                    descripcion: 'Alcance de Organización',
                }, {
                    idCheckListInicial: 2,
                    descripcion: 'Datos de infraestructura'
                },
                {
                    idCheckListInicial: 3,
                    descripcion: 'Datos de Produccion',
                }, {

                    idCheckListInicial: 4,
                    descripcion: 'Datos de activos',
                }, {
                    idCheckListInicial: 5,
                    descripcion: 'Declaración de sistemas'
                }, {
                    idCheckListInicial: 6,
                    descripcion: 'Declaración de personal'
                },
                {
                    idCheckListInicial: 7,
                    descripcion: 'Información financiera'
                },
                {
                    idCheckListInicial: 8,
                    descripcion: 'Obligaciones de la organización'
                },
                {
                    idCheckListInicial: 9,
                    descripcion: 'Declaración de obligaciones'
                },
                {
                    idCheckListInicial: 10,
                    descripcion: 'Cuentas Bancos'
                },
                {
                    idCheckListInicial: 11,
                    descripcion: 'Declaración de tributos y obligaciones labores'
                },
                {
                    idCheckListInicial: 12,
                    descripcion: 'Lista de principales clientes'
                },

            ],
            listaCheckListInicialGestion: [{
                    idCheckListInicial: 13,
                    descripcion: 'Gestion de calidad',
                }, {
                    idCheckListInicial: 14,
                    descripcion: 'Mantenimiento y calibración'
                },
                {
                    idCheckListInicial: 15,
                    descripcion: 'Compra, recepción y almacenes',
                }, {

                    idCheckListInicial: 16,
                    descripcion: 'Procesos sub contratados',
                }, {
                    idCheckListInicial: 17,
                    descripcion: 'Proceso productivo'
                },
                {
                    idCheckListInicial: 18,
                    descripcion: 'Gestión del personal'
                },
                {
                    idCheckListInicial: 19,
                    descripcion: 'Gestión comercial'
                },
                {
                    idCheckListInicial: 20,
                    descripcion: 'Gestión de seguridad y salud en el trabajo'
                },
                {
                    idCheckListInicial: 21,
                    descripcion: 'Gestión ambiental'
                },
                {
                    idCheckListInicial: 22,
                    descripcion: 'Gestión antisoborno'
                },

            ],

            // listaPlanStage1: [{
            //         idPlanStage1: 1,
            //         descripcion: 'Audits Plans',
            //     }, {
            //         idPlanStage1: 2,
            //         descripcion: 'Audit team assigments'
            //     },
            //     {
            //         idPlanStage1: 3,
            //         descripcion: 'Aplicant conflict of interest studies',
            //     },
            // ],
            // listaCheckList1: [{
            //     idCheckList1: 1,
            //     descripcion: 'report',
            // }, ],
            // listaPlanStage2: [{
            //         idPlanStage2: 1,
            //         descripcion: 'Planes Auditoria',
            //     }, {
            //         idPlanStage2: 2,
            //         descripcion: 'Asignación del equipo de auditor'
            //     },
            //     {
            //         idPlanStage2: 3,
            //         descripcion: 'Estudios de conflicto de interes solicitante',
            //     },
            //     {
            //         idPlanStage2: 4,
            //         descripcion: 'Reports',
            //     },
            //     {
            //         idPlanStage2: 5,
            //         descripcion: 'Audit team approval'
            //     }
            // ],
            // listaCheckList2: [{
            //     idCheckList2: 1,
            //     descripcion: 'report',
            // }, {
            //     idCheckList2: 2,
            //     descripcion: 'Check List 2'
            // },
            // {
            //     idCheckList2: 3,
            //     descripcion: 'Check List 3',
            // }, {

            //     idCheckList2: 4,
            //     descripcion: 'Check List 4',
            // }, {
            //     idCheckList2: 5,
            //     descripcion: 'Check List 5'
            // }, ],
            listaCheckListInciales: [],
            listaPacks: {
                listaPlanAuditoriaS1: [],
                listaAsignacionEquipoS1: [],
                listaEstudioConflictosS1: [],
                listaReportS1: [],
                listaPlanAuditoriaS2: [],
                listaAsignacionEquipoS2: [],
                listaEstudioConflictosS2: [],
                listaCheckListS2: [],
                listaReportS2: [],
                listaReportExpertoS2: [],
                listaNoClientFeedbackS2: [],
                listaNonConformityS2: [],
                listaAuditAprovalS2: [],
            },
            headerDocumentosPack: [{
                key: 'idPack',
                label: 'N°',
                class: 'text-center',
                width: '5%'
            }, {
                key: 'categoria',
                label: 'Categoría',
                width: '20%'
            }, {
                key: 'descripcion',
                label: 'Documento',
                width: '35%'

            }, {
                key: 'idPlantilla',
                label: 'Esquema',
                width: '40%'
            }, ],

            datosSession: {
                idCliente: null
            }

        }
    },
    methods: {
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },

        onFiltered(itemsFiltrados) {
            this.filasTotales = itemsFiltrados.length
            this.paginaActual = 1
        },

        // agregarPersonal(){
        //     let me = this;

        //     me.datosNuevoPersonal.listarPersonal.push({
        //         idPersona:'',
        //         nombre: me.datosNuevoPersonal.nombre
        //      })

        // },
        agregarAuditor() {
            let me = this;
            me.datosAuditoria.personalAuditoria.push({
                idPersona: null,
            })
        },
        quitarActividad(param) {
            let me = this;
            me.datosAuditoria.personalAuditoria.splice(param.index, 1);
        },
        // agregarActividad2(k) {
        //     let me = this;
        //     me.datosExperto.listaExperto.push({

        //     })
        // },
        // quitarActividad2(param) {
        //     let me = this;
        //     me.datosExperto.listaExperto.splice(param.index, 2);
        // },
        // agregarPersonal() {
        //     let me = this;
        //     me.disabled = true;
        //     axios
        //         .post(
        //             CONSTANTES.URL_RUTA_SERVICIOS + "cliente/comercial/registrar-auditoria-iqc",
        //             me.datosNuevoPersonal, {
        //                 headers: {
        //                     Authorization: `Bearer ${localStorage.token}`
        //                 },
        //             }
        //         )
        //         .then(function (response) {
        //             let color = response.data.resultado == 1 ? "success" : "error";
        //             me.disabled = false;
        //             if (response.data.alert) {
        //                 return me.swat(color, response.data.mensaje)
        //             }
        //             me.swat(color, response.data.mensaje)
        //             me.modalNuevoPersonal = false;
        //             me.listarPersonal();
        //         })
        //         .catch(function (error) {
        //             me.swat('error', 'Algo salió mal!')
        //             me.disabled = false;
        //         });
        // },
        listarPersonal() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "cliente/recursoshumanos/listar-usuarios-iqc", {
                    params: {
                        idCliente: this.datosSession.idCliente
                    }
                })
                .then(function (response) {
                    //Solo personal de IQCPeru no de sus sub empresas
                    //Solo personal que tengan usuario 
                    //Solo personal que su perfil (tipo de usuario) sea Auditor o derivados
                    for (let el of response.data) {
                        if ((el.idSubCliente == 0 || el.idSubCliente == null) && (el.tipoPerfil == 'Auditor Líder' || el.tipoPerfil == 'Experto Técnico' || el.tipoPerfil == 'Revisor' || el.tipoPerfil == 'Auditor')) {
                            me.listaPersonal.push({
                                idPersona: el.idPersona,
                                tipoPerfil: el.tipoPerfil,
                                usuario: el.usuario,
                                nombre: el.nombre,
                                puestoTrabajo: el.puestoTrabajo,
                                apellidoMaterno: el.apellidoMaterno,
                                apellidoPaterno: el.apellidoPaterno,
                                label: `${el.apellidoPaterno} ${el.apellidoMaterno}, ${el.nombre} - ${el.tipoPerfil}`
                            })
                        }
                    }
                })
                .catch(() => {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        // listarReports() {
        //     let me = this;
        //     axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.token}`;
        //     axios
        //         .get(CONSTANTES.URL_RUTA_SERVICIOS + 'cliente/pack/listar-pack-reports-s1', {
        //             params: {
        //                 estado: 2,
        //                 idCliente: me.$store.state.user.uidClient
        //             },
        //         })
        //         .then(function (response) {
        //             me.listaClientes = response.data;
        //             me.totalRows = me.listaClientes.length;
        //         })
        //         .catch(function (error) {
        //             me.swat('error', 'Algo salió mal!');
        //         });
        // },
        // listarClientes() {
        //     let me = this;
        //     axios.defaults.headers.common[
        //         "Authorization"
        //     ] = `Bearer ${localStorage.token}`;
        //     axios
        //         .get(CONSTANTES.URL_RUTA_SERVICIOS + "cliente/comercial/listar-clientes-iqc", {
        //             params: {
        //                 estado: 2,
        //                 idCliente: me.$store.state.user.uidClient
        //             }
        //         })
        //         .then(function (response) {
        //             me.listaClientes = response.data;
        //         })
        //         .catch(function (error) {
        //             me.swat('error', 'Algo salió mal!')
        //         });
        // },
        listarCheckListIniciales() {
            let me = this;
            axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + 'cliente/pack/listar-pack-checklist-inicial', {
                    params: {
                        idCliente: me.datosSession.idCliente,
                    },
                })
                .then(function (response) {
                    for (let k in response.data) {
                        me.listaCheckListInciales.push({
                            idPackCheckListInicial: response.data[k].idPackCheckListInicial,
                            codigo: `${response.data[k].codigo} | ${moment(response.data[k].fechaCreacion).format('YYYY-MM-DD HH:mm:ss')}`,
                        })
                    }
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!');
                });
        },
        obtenerAuditoria() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "cliente/auditorias/obtener-auditoria", {
                    params: {
                        idAuditoria: me.$route.params.id,
                    }
                })
                .then(function (response) {

                    me.datosAuditoria.idAuditoria = response.data[0].idAuditoria;
                    me.datosAuditoria.idCotizacionCertificacion = response.data[0].idCotizacionCertificacion;
                    me.datosAuditoria.fechaInicio = response.data[0].fechaInicio;
                    me.datosAuditoria.fechaFin = response.data[0].fechaFin;
                    me.datosAuditoria.normas = JSON.parse(response.data[0].normas);
                    me.datosAuditoria.tipoAuditoria = response.data[0].tipoAuditoria;
                    me.datosAuditoria.personalAuditoria = JSON.parse(response.data[0].personalAuditoria);
                    me.datosAuditoria.cargaModuloOrganizacional = JSON.parse(response.data[0].cargaModuloOrganizacional);
                    me.datosAuditoria.cargaModuloGestion = JSON.parse(response.data[0].cargaModuloGestion);
                    me.datosAuditoria.referenciasCheckListIniciales = response.data[0].referenciasCheckListIniciales;
                    me.datosAuditoria.referenciasDocumentosPack = JSON.parse(response.data[0].referenciasDocumentosPack);

                    // me.dataCheckListInicial.idPackCheckListInicial = response.data[0][0].idPackCheckListInicial;
                    // me.dataCheckListInicial.idAuditoria = response.data[0][0].idAuditoria;

                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.$router.push({
                        name: 'Asignacion cliente'
                    })
                });
        },
        listarPacks() {
            let me = this;
            axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + 'cliente/pack/listar-pack-estructuras', {
                    params: {
                        idCliente: me.datosSession.idCliente,
                    },
                })
                .then(response => {

                    const returnNormas = (normas) => {
                        return String(me.listaNormas
                            .filter(x => normas
                                .includes(x.idNorma))
                            .map(y => y.descripcion)
                            .join(', '))
                    }
                    const returnLabel = (el, value = "0",id = "item") => {
                        var o = Object.assign({}, el);
                        o.data = {
                            label: `${returnNormas(el.normas)} | ${moment(el.fechaCreacion).format("DD/MM/YYYY HH:mm:ss")}`,
                            [id]: value,
                        }
                        return o;
                    }
                    const returnLabelSN = (el, tipo, value = "0",id = "item") => {
                        var o = Object.assign({}, el);
                        o.data = {
                            label: `${tipo} | ${moment(el.fechaCreacion).format("DD/MM/YYYY HH:mm:ss")}`,
                            [id]: value,
                        }
                        return o;
                    }

                    me.listaPacks.listaPlanAuditoriaS1 = response.data[0].map(el => returnLabel(el,el.idPlanAuditoria,"idPlanAuditoria"));
                    me.listaPacks.listaAsignacionEquipoS1 = response.data[1].map(el => returnLabelSN(el, 'Asignación stage 1', el.idAsignacionEquipo,"idAsignacionEquipo"));
                    me.listaPacks.listaEstudioConflictosS1 = response.data[2].map(el => returnLabelSN(el, 'Estudio de conflicto stage 1', el.idEstudioConflictos,"idEstudioConflictos"));

                    me.listaPacks.listaReportS1 = response.data[3].map(el => returnLabel(el, el.idReport,"idReport"));

                    me.listaPacks.listaPlanAuditoriaS2 = response.data[4].map(el => returnLabel(el, el.idPlanAuditoria,"idPlanAuditoria"));
                    me.listaPacks.listaAsignacionEquipoS2 = response.data[5].map(el => returnLabelSN(el, 'Asignación stage 2', el.idAsignacionEquipo,"idAsignacionEquipo"));
                    me.listaPacks.listaEstudioConflictosS2 = response.data[6].map(el => returnLabelSN(el, 'Estudio de conflicto stage 2', el.idEstudioConflictos,"idEstudioConflictos"));

                    me.listaPacks.listaCheckListS2 = response.data[7].map(el => returnLabel(el, el.idCheckList,"idCheckList"));
                    me.listaPacks.listaReportS2 = response.data[8].map(el => returnLabel(el, el.idReport,"idReport"));
                    me.listaPacks.listaReportExpertoS2 = response.data[9].map(el => returnLabel(el, el.idTecnicalReport,"idTecnicalReport"));
                    me.listaPacks.listaNoClientFeedbackS2 = response.data[10].map(el => returnLabel(el, el.idClienteFeedback,"idClienteFeedback"));
                    me.listaPacks.listaNonConformityS2 = response.data[11].map(el => returnLabel(el, el.idReporteNC,"idReporteNC"));
                    me.listaPacks.listaAuditAprovalS2 = response.data[12].map(el => returnLabel(el, el.idAuditTeamApproval,"idAuditTeamApproval"));

                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!');
                });
        },
        
        registrarAuditoria() {
            let me = this;
            me.disabled = true;
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "cliente/auditorias/registrar-auditoria",
                    me.datosAuditoria, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.disabled = false;
                    if (response.data.alert) {
                        return me.swat(color, response.data.mensaje)

                    }
                    me.swat(color, response.data.mensaje)
                    me.$router.push({
                        name: 'Asignacion cliente'
                    })

                    // me.listarClientes();
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = false;
                });
        },
        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        },

    },

    mounted() {
        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.datosSession.idCliente = user.uidClient;
        }
        // this.listarReports();
        this.listarPersonal();
        this.listarCheckListIniciales();
        this.listarPacks();
        if (this.$route.params.id) {
            this.obtenerAuditoria();
        } else {
            if (localStorage.getItem('filterBusqueda')) {
                let filterBusqueda = JSON.parse(localStorage.getItem('filterBusqueda'))
                this.datosAuditoria.idCotizacionCertificacion = filterBusqueda.idCotizacionCertificacion
            }
        }

    }
}
</script>
